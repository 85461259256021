import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
//import Carta from './components/common/Carta';
import CategorySelect from './components/common/CategorySelect';

class App extends React.Component{
    render() {
        return (
          <Router>
            <div className='container '>
              <center>
                <Header/> 
              </center>

              <Routes>                   
                <Route exact path="/category-select/:catId" element={<CategorySelect/>}/> 
                <Route exact path="/" element={<CategorySelect/>}/> 
              </Routes>                  
             
              <center>
                <Footer/>
              </center>
              
            </div>
          </Router>
          

        );
      }    
}
export default App;
