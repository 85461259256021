import React, {useState ,useEffect} from "react";
import Producto from "./Producto";
import {Url} from "../../constants/globals";

function ProdsForCategory(props){

    const [prods, setProds] = useState([]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
   
    useEffect(() => {
        //fetch("http://localhost/hoster_backend/public/lit/prod/category/"+props.idCat+".json")      
        fetch(Url+"lit/prod/category/"+props.idCat+".json")      
        .then(res => res.json())
        .then(
            (data) => {                    
                setIsLoaded(true);
                setProds(data);
            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        )             
    }, []) 
    
    if (error) {
        return <div>Error: {error.message}</div>;
    }
    else if (!isLoaded) {
        return <div>Loading...</div>;
    }  
    else {        
        return (
            <div>
                {  
                 prods.map(prod => (
                    <Producto key = {prod.id}
                        name = {prod.name}    
                        description = {prod.description} 
                        activePrice = {prod.activePrice}   
                        imageurl = {prod.image}                
                    />                        
                ))
                }
            </div>                         
        );
         
    }
}

export default ProdsForCategory;