import React , { useState, useEffect }  from "react";
import ProdsForCategory from "./ProdsForCategory";
import CategoriaProd from "./CategoriaProd";
import {Url} from "../../constants/globals";
import { useParams } from "react-router-dom";

function CategorySelect(){

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [cats, setCats] = useState([]);
    let { catId } = useParams();
 
    if(typeof catId === 'undefined'){
        catId = 'default';
    }
    useEffect(() => {
        fetch(`${Url}lit/prod/category-select/${catId}`)
            .then(res => res.json())
            .then(
                (data) => {                    
                    setIsLoaded(true);
                    setCats(data);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [Url, catId])   
    if (error) {
        return <div>Error: {error.message}</div>;
    }
    else if (!isLoaded) {
        return <div>Loading...</div>;
    }  
    else {
        
        let category = cats.sort(((a, b) => a.priority - b.priority));
       
        return (
            <div>
                {  
                 category.map(cat => (
                    <div key = {cat.id}> 
                    <CategoriaProd 
                        name = {cat.name}                        
                    />
                    <ProdsForCategory idCat = {cat.id} />    
                    </div>  
                   
                ))
                }
            </div>                         
         );
              
    }
          
}
export default CategorySelect;