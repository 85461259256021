import React from "react";

const Footer = () =>{
    return (
        <footer className="footer mt-auto py-3 bg-light">
            <div className="container">
                <div className="text-muted col-12">Valhalla ©</div>
                <div className="text-muted col-12">Powered by: <a href="https://litusgroup.com">LITUS</a></div>
            </div>
        </footer>
    );

}
export default Footer;