import React, {useState ,useEffect} from "react";


function Producto(props){
           
    return (        
        <div className="row product-row">
            <div className="col-lg-1 col-md-2 col-3">
                <div className="circular--portrait"><img src={props.imageurl} alt="producto" /></div>                
            </div>           
            <div className="col-lg-11 col-md-10 col-9">
                <h6>{props.name}</h6>
                <div className="small">{props.description}</div>
                <div className="priceActive"> {props.activePrice} CUP</div>
            </div>            
        </div>
    );
        
}
export default Producto;