import React from "react";

import NavCategory from "../menu/NavCategory";


const Header = () =>{
    return (
        /*
        <header className="d-flex justify-content-center py-3 mb-4 border-bottom ">           
            <a href="/" className=" text-dark text-decoration-none">
                <img src="./image/LOGO.jpg" width='150px' height='150px'/>
            </a>
        </header>
        */
        
        <header >
            <a href="/" className=" text-dark text-decoration-none">
                <img src="./image/LOGO.jpg" width='150px' height='150px'/>
            </a>               
            <NavCategory />            
        </header>
        

    );

}
/*
function CategorySelect(){
    
   return  <CategorySelect category='2'/>
}
*/
export default Header;