import React , { useState, useEffect }  from "react";
import ItemMenuCategory from "./ItemMenuCategory";
import {Url} from "../../constants/globals";

function NavCategory(){

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [cats, setCats] = useState([]);

    useEffect(() => {        
        fetch(`${Url}api/prod_category_menus.json/`)
            .then(res => res.json())
            .then(
                (data) => {                    
                    setIsLoaded(true);
                    setCats(data);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [Url])   
    if (error) {
        return <div>Error: {error.message}</div>;
    }
    else if (!isLoaded) {
        return <div>Loading...</div>;
    }  
    else {
        
        let category = cats.sort(((a, b) => a.priority - b.priority));
       
        return (
            <nav className="scroll ">
                {  
                 category.map(cat => (
                    
                    <ItemMenuCategory 
                        id = {cat.id}
                        name = {cat.name}                      
                    />                                   
                   
                ))
                }
            </nav>                         
         );
              
    }
          
}
export default NavCategory;